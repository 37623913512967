import React, { useContext } from 'react'
import Textarea from '@cloudscape-design/components/textarea'
import { AppContext } from '../App'

export function RadTextarea ({ ...rest }) {
  const { connected } = useContext(AppContext)

  return (
    <Textarea
      disabled={!connected || rest.disabled}
      {...rest}
    />
  )
}
