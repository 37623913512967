import React, { useContext } from 'react'
import DatePicker from '@cloudscape-design/components/date-picker'
import { AppContext } from '../App'

export function RadDatePicker ({ placeholder, ...rest }) {
  const { connected } = useContext(AppContext)

  return (
    <DatePicker
      {...rest}
      placeholder={placeholder ?? 'YYYY/MM/DD'}
      nextMonthAriaLabel='Next month'
      previousMonthAriaLabel='Previous month'
      todayAriaLabel='Today'
      disabled={!connected || rest.disabled}
    />
  )
}
