import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
// import { RadTable } from '../common/RadTable'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadPagination } from '../common/RadPagination'
import { RadButton } from '../common/RadButton'
import { formatDate, humanReadableId, numberWithCommas } from '../common/utilities'

export function CaseList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: cases, count } = useGet(`/api/case?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)

  if (cases) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + numberWithCommas(count) + ')'}
          >
            Focus List Enrollments
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/enrollment/${humanReadableId(item.id)}`}>{humanReadableId(item.id)}</RadLink>,
              sections: [
                {
                  id: 'person',
                  header: 'Student',
                  content: item => <RadLink href={`/student/${item.person.id}`}>{`${item.person?.firstName} ${item.person.preferredName ? '"' + item.person.preferredName + '" ' : ''} ${item.person?.lastName}`}</RadLink>
                },
                {
                  id: 'indicator',
                  header: 'Indicator',
                  content: item => item.service.name
                },
                {
                  id: 'opened',
                  header: 'Opened',
                  content: item => item.openedAt != null ? formatDate(item.openedAt) : '-'
                },
                {
                  id: 'status',
                  header: 'Status',
                  content: item => item.status?.name ?? '-'
                },
                {
                  id: 'owner',
                  header: 'Owner',
                  content: item => <RadLink href={`/user/${item.owner.id}`}>{item.owner.name}</RadLink>
                },
                {
                  id: 'champions',
                  header: 'Champions',
                  content: item =>
                    <RadSpaceBetween direction='horizontal' size='xxs'>
                      {
                        item.champions.length > 0 && item.champions.map(champion =>
                          <RadLink key={champion.id} href={`/person/${champion.id}`}>
                            {champion.name}
                          </RadLink>
                        )
                      }
                      {item.champions.length === 0 && <div key={0}>-</div>}
                    </RadSpaceBetween>
                },
                {
                  id: 'closedAt',
                  header: 'Closed Date',
                  content: item => item.closedAt != null ? formatDate(item.closedAt) : '-'
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={cases}
            variant='full-page'
            filter={
              <RadTextFilter
                filteringPlaceholder='Search'
                filteringAriaLabel='Search focus list enrollments'
                filteringText={filteringText}
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText)
                  setCurrentPageIndex(1)
                  searchParams.set('page', 1)
                  if (detail.filteringText) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
          />
          // <RadTable
          //   columnDefinitions={[
          //     {
          //       id: 'number',
          //       header: 'Number',
          //       cell: e => <RadLink href={`/enrollment/${humanReadableId(e.id.toString())}`}>{humanReadableId(e.id)}</RadLink>
          //     },
          //     {
          //       id: 'person',
          //       header: 'Student',
          //       cell: e => <RadLink href={`/student/${e.person.id}`}>{`${e.person?.firstName} ${e.person.preferredName ? '"' + e.person.preferredName + '" ' : ''} ${e.person?.lastName}`}</RadLink>
          //     },
          //     {
          //       id: 'service',
          //       header: 'Indicator',
          //       cell: e => e.service.name
          //     },
          //     {
          //       id: 'openedAt',
          //       header: 'Opened',
          //       cell: e => formatDate(e.openedAt)
          //     },
          //     {
          //       id: 'status',
          //       header: 'Status',
          //       cell: e => e.status
          //     },
          //     {
          //       id: 'owner',
          //       header: 'Owner',
          //       cell: e => <RadLink href={`/user/${e.owner.id}`}>{e.owner.name}</RadLink>
          //     },
          //     {
          //       id: 'champion',
          //       header: 'Champion',
          //       cell: e => e.champion ?? '-'
          //     },
          //     {
          //       id: 'closedAt',
          //       header: 'Closed Date',
          //       cell: e => formatDate(e.closedAt)
          //     }
          //   ]}
          //   items={cases}
          //   variant='full-page'
          //   filter={
          //     <RadTextFilter
          //       filteringPlaceholder='Search'
          //       filteringText={filteringText}
          //       onChange={({ detail }) => setFilteringText(detail.filteringText)}
          //       onDelayedChange={({ detail }) => {
          //         setSearchText(detail.filteringText)
          //         setCurrentPageIndex(1)
          //         searchParams.set('page', 1)
          //         if (detail.filteringText) {
          //           searchParams.set('search', detail.filteringText)
          //         } else {
          //           searchParams.delete('search')
          //         }
          //         setSearchParams(searchParams)
          //       }}
          //     />
          //   }
          //   pagination={
          //     <RadPagination
          //       currentPageIndex={currentPageIndex}
          //       pagesCount={Math.ceil(count / pageLength)}
          //       onChange={({ detail }) => {
          //         searchParams.set('page', detail.currentPageIndex)
          //         setSearchParams(searchParams)
          //         setCurrentPageIndex(detail.currentPageIndex)
          //       }}
          //       ariaLabels={{
          //         nextPageLabel: 'Next page',
          //         previousPageLabel: 'Previous page',
          //         pageLabel: pageNumber => `Page ${pageNumber} of all pages`
          //       }}
          //     />
          //   }
          // />
        }
      />
    )
  }
}
