import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadAvatar } from '../common/RadAvatar'
import { RadCards } from '../common/RadCards'
import { RadButton } from '../common/RadButton'
import { formatDate, getGrade, humanReadableId } from '../common/utilities'

export function SessionDetail () {
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const { data: session } = useGet(`/api/session/${sessionId}`)
  const confirmDelete = useConfirm('Delete session?', 'Delete session permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/session/${sessionId}`, () => { navigate('/session') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (session) {
    const blockedPeopleCount = session.people.filter((x) => x.firstName == null).length

    return (
      <RadAppLayout
        name={humanReadableId(session.id)}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {humanReadableId(session.id)}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>

            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={1} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Date
                    </RadBox>
                    {formatDate(session.date)}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Description
                    </RadBox>
                    {session.description ?? '-'}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Administered By
                    </RadBox>
                    <RadLink href={`/user/${session.owner.id}`}>{session.owner.name}</RadLink>
                  </div>
                  {
                    session.reason &&
                      <RadBox>
                        <RadBox variant='awsui-key-label'>
                          Reason
                        </RadBox>
                        {session.reason?.name ?? ''}
                      </RadBox>
                  }
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>

            <RadHeader
              variant='h2'
              counter={'(' + session.deliveries.length + ')'}
            >
              Interventions
            </RadHeader>

            <RadCards
              cardDefinition={{
                header: item => item.program.name,
                sections: [
                  {
                    id: 'tier',
                    header: 'Tier',
                    content: item => item.tier.name
                  },
                  {
                    id: 'indicator',
                    header: 'Indicator',
                    content: item => item.serviceDelivery.service.name
                  },
                  {
                    id: 'intervention',
                    header: 'Intervention',
                    content: item => item.serviceDelivery.name
                  },
                  {
                    id: 'minutes',
                    header: 'Minutes',
                    content: item => item.minutes
                  },
                  {
                    id: 'skills',
                    header: 'Skills',
                    content: item => item.skills.length > 0 ? item.skills.map((x) => x.skill.name).join(', ') : '-'
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={session.deliveries}
              variant='full-page'
            />

            {/* <RadTable
              header={
                <RadHeader
                  variant='h2'
                  counter={'(' + session.deliveries.length + ')'}
                >
                  Interventions
                </RadHeader>
              }
              variant='container'
              wrapLines
              items={session.deliveries}
              columnDefinitions={[
                {
                  id: 'program',
                  header: 'Program',
                  cell: e => e.program.name
                },
                {
                  id: 'tier',
                  header: 'Tier',
                  cell: e => e.tier.name
                },
                {
                  id: 'service',
                  header: 'Indicator',
                  cell: e => e.serviceDelivery.service.name
                },
                {
                  id: 'serviceDelivery',
                  header: 'Intervention',
                  cell: e => e.serviceDelivery.name
                },
                {
                  id: 'minutes',
                  header: 'Minutes',
                  cell: e => e.minutes
                },
                {
                  id: 'skills',
                  header: 'Skills',
                  cell: e => e.skills.length > 0 ? e.skills.map((x) => x.skill.name).join(', ') : '-'
                }
              ]}
            /> */}

            <RadHeader
              variant='h2'
              counter={`(${session.people.length - blockedPeopleCount})`}
            >
              Students
            </RadHeader>
            {
              blockedPeopleCount > 0 &&
                <RadBox padding={{ bottom: 'l' }}>
                  * This session has {blockedPeopleCount} {blockedPeopleCount > 1 ? 'students' : 'student'} you do not have permission to view
                </RadBox>
            }

            <RadCards
              ariaLabels={{
                itemSelectionLabel: (e, t) => `select ${t.name}`,
                selectionGroupLabel: 'Item selection'
              }}
              cardDefinition={{
                header: item => (
                  <>
                    <RadAvatar
                      src={item.photoUrl} // Chipi - add photo_url field
                      size='large'
                      name={`${item.firstName} ${item.lastName}`}
                      referrerPolicy='no-referrer'
                    />
                    <br />
                    <RadLink fontSize='heading-m' href={`/student/${item.id}`}>{item.fullName}</RadLink>
                  </>
                ),
                sections: [
                  {
                    id: 'schoolEnrollment',
                    content: item => item.schoolEnrollments.length > 0
                      ? <><div>{item.schoolEnrollments[0].school.name}</div><div>{getGrade(item.schoolEnrollments[0].grade)}</div></>
                      : <div />
                  },
                  {
                    id: 'notes',
                    header: 'Notes',
                    content: item => item.SessionPerson.notes
                  },
                  {
                    id: 'messages',
                    header: 'Messages',
                    content: student => {
                      const { twilioConversationSid, twilioMessages } = session

                      return (
                        twilioConversationSid != null && (
                          <ul>
                            {twilioMessages.split(',').map((m) => {
                              const [dateCreated, messageSid] = m.split('|')

                              return (
                                <li key={m}>
                                  <RadLink
                                    href={[
                                      `/student/${student.id}/guardian-messages`,
                                      `?conversation=${twilioConversationSid}`,
                                      `&message=${messageSid}`
                                    ].join('')}
                                    variant='primary'
                                  >
                                    {formatDate(dateCreated, true)}
                                  </RadLink>
                                </li>
                              )
                            })}
                          </ul>
                        )
                      )
                    }
                  },
                  {
                    id: 'minutes',
                    header: 'Minutes',
                    content: item => item.SessionPerson.minutes
                      ? `${item.SessionPerson.minutes} minutes *`
                      : ''
                  }
                ]
              }}
              visibleSections={[
                'schoolEnrollment', 'notes', 'minutes',
                session.twilioConversationSid != null ? 'messages' : null
              ]}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={session.people.filter((x) => x.firstName != null)}
              variant='full-page'
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}
