import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadSelect } from '../common/RadSelect'
import { RadButton } from '../common/RadButton'
import { RadTextarea } from '../common/RadTextarea'

export function ProgramEdit () {
  const navigate = useNavigate()
  const { programId } = useParams()
  const { data: program } = useGet(programId ? `/api/program/${programId}` : null)
  const [formValues, setFormValues] = useState()
  const { data: typeOptions } = useGet('/api/option/type?entity=program')
  const { data: serviceOptions } = useGet('/api/option/service')
  const create = usePost('/api/program', formValues, (resp) => { navigate(`/program/${resp.id}`) })
  const update = usePut(`/api/program/${programId}`, formValues, (resp) => { navigate(`/program/${programId}`) })

  useEffect(() => {
    const defaultFormValues = { services: [] }
    setFormValues(program ?? defaultFormValues)
  }, [program])

  async function cancel () {
    if (programId) {
      navigate(`/program/${programId}`)
    } else {
      navigate('/program')
    }
  }

  async function saveChanges () {
    if (program?.id) { update() } else { create() }
  }

  if (formValues && typeOptions && serviceOptions) {
    if (formValues) {
      return (
        <RadAppLayout
          name={formValues.name}
          contentHeader={
            <RadHeader variant='h1'>
              {formValues.id ? 'Edit Program' : 'New Program'}
            </RadHeader>
          }
          content={
            <form onSubmit={e => e.preventDefault()}>
              <RadForm
                actions={
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                    <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                  </RadSpaceBetween>
                }
              >
                <RadSpaceBetween size='l'>
                  <RadContainer
                    header={
                      <RadHeader variant='h2'>
                        Details
                      </RadHeader>
                    }
                  >
                    <RadSpaceBetween size='l'>
                      <RadFormField label='Name' field='name'>
                        <RadInput
                          ariaRequired
                          placeholder='Enter name'
                          value={formValues.name ?? ''}
                          onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Type' field='typeId'>
                        <RadSelect
                          selectedOption={typeOptions.find(x => x.value === (formValues.typeId?.toString() ?? ''))}
                          onChange={({ detail }) => {
                            if (detail.selectedOption.value !== '') {
                              setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                            } else {
                              setFormValues({ ...formValues, typeId: null })
                            }
                          }}
                          options={typeOptions}
                          selectedAriaLabel='Selected'
                          empty='No matches found'
                        />
                      </RadFormField>
                      <RadFormField label='Description' field='description'>
                        <RadTextarea
                          placeholder='Enter description'
                          value={formValues.description ?? ''}
                          onChange={event => setFormValues({ ...formValues, description: event.detail.value })}
                        />
                      </RadFormField>
                    </RadSpaceBetween>
                  </RadContainer>
                  <RadContainer
                    header={
                      <RadHeader variant='h2'>
                        Indicators
                      </RadHeader>
                    }
                  >
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const services = formValues.services
                        services.push({ uuid: uuidv4() })
                        setFormValues({ ...formValues, services })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const services = [...formValues.services]
                        services.splice(itemIndex, 1)
                        setFormValues({ ...formValues, services })
                      }}
                      items={formValues.services}
                      addButtonText='Add indicator'
                      definition={[
                        {
                          label: 'Indicators',
                          control: item => {
                            const filteredOptions = serviceOptions.filter((x) => !formValues.services.map((y) => y.id?.toString()).includes(x.value))
                            return (
                              <RadFormField field={`services.${item.id || item.uuid}.id`}>
                                <RadSelect
                                  selectedOption={serviceOptions.find(x => x.value === item.id?.toString())}
                                  onChange={({ detail }) => {
                                    const services = formValues.services
                                    const service = formValues.services.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                    service.id = parseInt(detail.selectedOption.value)
                                    service.firstName = detail.selectedOption.label.split(' ')[0]
                                    service.lastName = detail.selectedOption.label.split(' ')[1]
                                    setFormValues({ ...formValues, services })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose an indicator'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No indicators associated with this program.'
                    />
                  </RadContainer>
                </RadSpaceBetween>
              </RadForm>
            </form>
          }
        />
      )
    }
  }
}
