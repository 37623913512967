import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'
import { RadBox } from '../common/RadBox'
import { RadTable } from '../common/RadTable'
import { RadPagination } from '../common/RadPagination'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadGrid } from '../common/RadGrid'
import { RadSelect } from '../common/RadSelect'
import { RadDatePicker } from '../common/RadDatePicker'
import { formatDate, humanReadableId, isValidDate, numberWithCommas } from '../common/utilities'

export function SessionList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedUserOption, setSelectedUserOption] = useState()
  const [selectedServiceOption, setSelectedServiceOption] = useState()
  const [selectedTierOption, setSelectedTierOption] = useState()
  const [fromDate, setFromDate] = useState(searchParams.get('from') ?? '')
  const [toDate, setToDate] = useState(searchParams.get('to') ?? '')
  const [userFilterText, setUserFilterText] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: sessions, count } = useGet(
    selectedUserOption != null
      ? `/api/session?search=${searchText}` +
      `&limit=${pageLength}` +
      `&offset=${(currentPageIndex - 1) * pageLength}` +
      `&administeredById=${selectedUserOption?.value !== '0' ? selectedUserOption?.value : ''}` +
      `&serviceId=${selectedServiceOption?.value ?? ''}` +
      `&tierId=${selectedTierOption?.value ?? ''}` +
      `&from=${isValidDate(fromDate) ? fromDate : ''}` +
      `&to=${isValidDate(toDate) ? toDate : ''}`
      : null
  )
  const { data: userInfo } = useGet('/api/user/current')
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}&required=true`)
  const { data: serviceOptions } = useGet('/api/option/service?required=true&any=true')
  const { data: tierOptions } = useGet('/api/option/type?entity=tier&required=true&any=true')
  const { data: administeredBy } = useGet(searchParams.get('administeredBy') !== null && parseInt(searchParams.get('administeredBy')) > 1 ? `/api/user/${searchParams.get('administeredBy')}` : null)

  useEffect(() => {
    if (userInfo != null && searchParams.get('administeredBy') == null) {
      setSelectedUserOption({ value: userInfo.id.toString(), label: userInfo.name })
    }
    if (selectedUserOption == null && searchParams.get('administeredBy') != null && administeredBy != null) {
      setSelectedUserOption({ value: administeredBy.id.toString(), label: administeredBy.name.replace('Migration', 'Auto-generated') })
    }
    if (selectedUserOption == null && searchParams.get('administeredBy') === '0') {
      setSelectedUserOption({ value: '0', label: 'Any administrator' })
    }
    if (selectedServiceOption == null && serviceOptions != null) {
      const serviceOption = serviceOptions.find((x) => x.value === searchParams.get('indicatorId'))
      setSelectedServiceOption(serviceOption)
    }
    if (searchParams.get('indicatorId') == null) {
      setSelectedServiceOption({ value: '', label: 'Any indicator' })
    }
    if (searchParams.get('tierId') == null) {
      setSelectedTierOption({ value: '', label: 'Any tier' })
    }
  }, [userInfo, administeredBy, serviceOptions])

  useEffect(() => {
    if (userOptions != null) {
      userOptions.unshift({ value: '0', label: 'Any administrator' })
    }
  }, [userOptions])

  if (sessions != null && selectedUserOption != null && userOptions != null && serviceOptions != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/session/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + numberWithCommas(count) + ')'}
          >
            Sessions
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-l' href={`/session/${humanReadableId(item.id)}`}>{humanReadableId(item.id)}</RadLink>
              ),
              sections: [
                {
                  id: 'date',
                  header: 'Date',
                  content: item => formatDate(item.date)
                },
                {
                  id: 'administeredBy',
                  header: 'Administered By',
                  content: item => <RadLink href={`/user/${item.owner.id}`}>{item.owner.name}</RadLink>
                },
                {
                  id: 'description',
                  header: 'Description',
                  content: item => item.description ?? '-'
                },
                {
                  id: 'deliveries',
                  content: item =>
                    <RadTable
                      columnDefinitions={[
                        {
                          id: 'program',
                          header: 'Program',
                          cell: e => e.program.name
                        },
                        {
                          id: 'tier',
                          header: 'Tier',
                          cell: e => e.tier.name
                        },
                        {
                          id: 'indicator',
                          header: 'Indicator',
                          cell: e => e.serviceDelivery.service.name
                        },
                        {
                          id: 'intervention',
                          header: 'Intervention',
                          cell: e => e.serviceDelivery.name
                        },
                        {
                          id: 'minutes',
                          header: 'Minutes',
                          cell: e => e.minutes
                        }
                      ]}
                      items={item.deliveries}
                      trackBy='id'
                      variant='embedded'
                    />
                },
                {
                  id: 'people',
                  content: item => {
                    return (
                      <>
                        {item.people.length > 12 && <div><b>Students</b> ({item.people.length - (item.blockedPersonCount ?? 0)})</div>}
                        {item.people.length < 12 &&
                          <RadTable
                            columnDefinitions={[
                              {
                                id: 'student',
                                header: 'Student',
                                width: '40%',
                                cell: e => <RadLink href={`/student/${e.id}`}>{e.fullName}</RadLink>
                              },
                              {
                                id: 'notes',
                                header: 'Notes',
                                cell: e => e.SessionPerson.notes ?? '-'
                              }
                            ]}
                            items={item.people.filter((x) => x.firstName != null)}
                            trackBy='id'
                            variant='embedded'
                            wrapLines
                          />}
                        {item.blockedPersonCount &&
                          <RadBox padding={{ top: 's' }}>
                            {`* This session has ${item.blockedPersonCount} ${item.blockedPersonCount > 1 ? 'students' : 'student'} who you do not have permission to view.`}
                          </RadBox>}
                      </>
                    )
                  }
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 }
            ]}
            items={sessions}
            variant='full-page'
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xxs: 6, m: 2 } },
                  { colspan: { default: 12, xxs: 3, m: 2 } },
                  { colspan: { default: 12, xxs: 3, m: 2 } },
                  { colspan: { default: 12, xxs: 3, m: 2 } },
                  { colspan: { default: 12, xxs: 3, m: 2 } },
                  { colspan: { default: 12, xxs: 3, m: 2 } }
                ]}
              >
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search sessions'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.set('page', 1)
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadSelect
                  selectedOption={selectedServiceOption}
                  onChange={({ detail }) => {
                    setSelectedServiceOption(detail.selectedOption)
                    if (detail.selectedOption.value !== '') {
                      searchParams.set('indicatorId', detail.selectedOption.value)
                    } else {
                      searchParams.delete('indicatorId')
                    }
                    setSearchParams(searchParams)
                  }}
                  options={serviceOptions ?? []}
                  filteringType='auto'
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Select indicator'
                />
                <RadSelect
                  ariaRequired
                  selectedOption={selectedTierOption}
                  onChange={({ detail }) => {
                    setSelectedTierOption(detail.selectedOption)
                    if (detail.selectedOption.value !== '') {
                      searchParams.set('tierId', detail.selectedOption.value)
                    } else {
                      searchParams.delete('tierId')
                    }
                    setSearchParams(searchParams)
                  }}
                  options={(tierOptions ?? []).map((x) => { return { value: x.value, label: x.value !== '' ? 'Tier ' + x.label : x.label } })}
                  enteredTextLabel={value => value}
                  placeholder='Any tier'
                  selectedAriaLabel='Selected'
                />
                <RadDatePicker
                  onChange={({ detail }) => {
                    if (detail.value !== '' && isValidDate(detail.value)) {
                      searchParams.set('from', detail.value)
                    } else {
                      searchParams.delete('from')
                    }
                    setSearchParams(searchParams)
                    setFromDate(detail.value)
                  }}
                  value={fromDate}
                  placeholder='From any date'
                  openCalendarAriaLabel={selectedDate =>
                    'Choose from date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                />
                <RadDatePicker
                  onChange={({ detail }) => {
                    if (detail.value !== '' && isValidDate(detail.value)) {
                      searchParams.set('to', detail.value)
                    } else {
                      searchParams.delete('to')
                    }
                    setSearchParams(searchParams)
                    setToDate(detail.value)
                  }}
                  value={toDate}
                  placeholder='To any date'
                  openCalendarAriaLabel={selectedDate =>
                    'Choose to date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                />
                <RadSelect
                  selectedOption={selectedUserOption}
                  onChange={({ detail }) => {
                    setSelectedUserOption(detail.selectedOption)
                    if (detail.selectedOption.value !== '' && detail.selectedOption.value !== userInfo.id.toString()) {
                      searchParams.set('administeredBy', detail.selectedOption.value)
                    } else {
                      searchParams.delete('administeredBy')
                    }
                    setSearchParams(searchParams)
                  }}
                  options={userOptions ?? []}
                  filteringType='manual'
                  onLoadItems={({ detail }) => setUserFilterText(detail.filteringText)}
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Select owner'
                  empty={userFilterText ? 'No matches found' : null}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
          />
        }
      />
    )
  }
}
