import { v4 as uuidv4 } from 'uuid'
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadTextarea } from '../common/RadTextarea'
import { RadSelect } from '../common/RadSelect'
import { RadButton } from '../common/RadButton'
import { RadDatePicker } from '../common/RadDatePicker'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import Spinner from '@cloudscape-design/components/spinner'
import { RadDivider } from '../common/RadDivider'

export function CaseEdit () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { enrollmentId } = useParams()
  const { data: casey } = useGet(enrollmentId ? `/api/case/${enrollmentId}` : null)
  const [personOrganizationId, setPersonOrganizationId] = useState()
  const [showChampion, setShowChampion] = useState(false)
  const { data: userInfo } = useGet('/api/user/current')
  const studentId = searchParams.get('studentId')
  const { data: person } = useGet(studentId ? `/api/person/${studentId}?type=student` : null)
  const defaultFormValues = useMemo(() => ({
    statusId: 12, // Active
    ownerId: userInfo?.id,
    owner: { name: userInfo?.name },
    champions: [],
    goals: []
  }), [userInfo])
  const [formValues, setFormValues] = useState(defaultFormValues)

  const getPayload = () => ({
    ...formValues,
    champions: formValues.champions.filter(x => x.id).map(x => x.id),
    goals: formValues.goals
      .filter(x => x.goalTypeId || x.goalProgressId || x.targetDate || x.text)
      .map(x => ({
        uuid: x.uuid,
        id: x.id,
        goalTypeId: x.goalTypeId,
        goalProgressId: x.goalProgressId,
        targetDate: x.targetDate,
        text: x.text
      }))
  })

  const create = usePost(
    '/api/case',
    getPayload(),
    (resp) => { navigate(`/enrollment/${resp.id}`) }
  )
  const update = usePut(
    `/api/case/${enrollmentId}`,
    getPayload(),
    (resp) => { navigate(`/enrollment/${enrollmentId}`) }
  )

  useEffect(() => {
    setPersonOrganizationId(casey?.person.schoolEnrollments[0]?.school?.organizationId)
    setShowChampion(
      (userInfo?.roles ?? []).some(x => x.name === 'Admin' || [2, 3].includes(x.primaryProgramId))
    )
    if (person != null) {
      defaultFormValues.personId = person.id
      defaultFormValues.person = person
    }
    setFormValues({ ...defaultFormValues, ...casey })
  }, [casey, userInfo, person, defaultFormValues])

  async function cancel () {
    if (enrollmentId) {
      navigate(`/enrollment/${enrollmentId}`)
    } else {
      navigate('/enrollment')
    }
  }

  async function saveChanges (e) {
    e.preventDefault()
    if (enrollmentId) { update() } else { create() }
  }

  if (formValues != null) {
    return (
      <RadAppLayout
        name={enrollmentId}
        contentHeader={
          <RadHeader variant='h1'>
            {enrollmentId ? 'Edit' : 'New'} Focus List Enrollment
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={(e) => saveChanges(e)}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <Details
                  {...{
                    personOrganizationId,
                    setPersonOrganizationId,
                    formValues,
                    setFormValues
                  }}
                />
                {showChampion && <Champions {...{ formValues, setFormValues }} />}
                <Goals {... { formValues, setFormValues }} />
                <Close {...{ formValues, setFormValues }} />
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function Details ({ personOrganizationId, setPersonOrganizationId, formValues, setFormValues }) {
  const [studentFilterText, setStudentFilterText] = useState('')
  const [userFilterText, setUserFilterText] = useState('')
  const { data: serviceOptions } = useGet('/api/option/service')
  const { data: statusOptions } = useGet('/api/option/type?entity=status')
  const { data: userOptions } = useGet(personOrganizationId
    ? `/api/option/user?search=${userFilterText}&organizationIds=${personOrganizationId}`
    : null
  )
  const { data: studentOptions } = useGet([
    '/api/person?type=student&options=true',
    `search=${studentFilterText}`
  ].join('&'))

  const loading = serviceOptions == null ||
    statusOptions == null ||
    (personOrganizationId && userOptions == null) ||
    studentOptions == null

  if (loading) {
    return (<Spinner />)
  }

  return (
    <RadContainer
      header={
        <RadHeader variant='h1'>Details</RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadFormField label='Student' field='personId'>
          <RadSelect
            ariaRequired
            selectedOption={formValues.personId ? { value: formValues.personId.toString(), label: formValues.person.fullName } : null}
            onChange={({ detail }) => {
              setPersonOrganizationId(detail.selectedOption.organizationId)
              setFormValues({ ...formValues, personId: parseInt(detail.selectedOption.value), person: { fullName: detail.selectedOption.label } })
            }}
            options={studentOptions ?? []}
            filteringType='manual'
            onLoadItems={({ detail }) => {
              setStudentFilterText(detail.filteringText)
            }}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a student'
            empty={studentFilterText ? 'No matches found' : null}
          />
        </RadFormField>
        <RadFormField label='Opened' field='openedAt'>
          <RadDatePicker
            onChange={({ detail }) => setFormValues({ ...formValues, openedAt: detail.value })}
            value={formValues.openedAt ?? ''}
            openCalendarAriaLabel={selectedDate =>
              'Choose a date' +
            (selectedDate
              ? `, selected date is ${selectedDate}`
              : '')}
          />
        </RadFormField>
        <RadFormField label='Indicator' field='serviceId'>
          <RadSelect
            filteringType='auto'
            selectedOption={serviceOptions.find(x => x.value === formValues.serviceId?.toString())}
            onChange={({ detail }) => setFormValues({ ...formValues, serviceId: parseInt(detail.selectedOption.value) })}
            options={serviceOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose an indicator'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Description' field='description'>
          <RadTextarea
            placeholder='Enter description'
            value={formValues.description ?? ''}
            onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
          />
        </RadFormField>
        <RadFormField label='Status'>
          <RadSelect
            selectedOption={statusOptions.find(x => x.value === formValues.statusId?.toString())}
            onChange={({ detail }) => {
              if (detail.selectedOption.value === '') {
                setFormValues({ ...formValues, statusId: null })
              } else {
                setFormValues({ ...formValues, statusId: parseInt(detail.selectedOption.value) })
              }
            }}
            options={statusOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a status'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Owner' field='ownerId'>
          <RadSelect
            ariaRequired
            selectedOption={formValues.ownerId ? { value: formValues.ownerId.toString(), label: formValues.owner.name } : null}
            onChange={({ detail }) => {
              setFormValues({ ...formValues, ownerId: parseInt(detail.selectedOption.value), owner: { name: detail.selectedOption.label } })
            }}
            options={userOptions ?? []}
            filteringType='manual'
            onLoadItems={({ detail }) => {
              setUserFilterText(detail.filteringText)
            }}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a user'
            empty={userFilterText ? 'No matches found' : null}
            disabled={!formValues.personId}
          />
        </RadFormField>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Champions ({ formValues, setFormValues }) {
  const [championFilterText, setChampionFilterText] = useState('')
  const { data: championOptions } = useGet([
    '/api/person?type=person&options=true',
    `search=${championFilterText}`
  ].join('&'))

  if (championOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h1'>Champions</RadHeader>
        }
      >
        <RadSpaceBetween size='l'>
          <RadAttributeEditor
            onAddButtonClick={() => {
              const { champions } = formValues
              champions.push({ uuid: uuidv4() })
              setFormValues({ ...formValues, champions })
            }}
            onRemoveButtonClick={({
              detail: { itemIndex }
            }) => {
              const { champions } = formValues
              champions.splice(itemIndex, 1)
              setFormValues({ ...formValues, champions })
            }}
            items={formValues.champions}
            addButtonText='Add new champion'
            definition={[
              {
                label: 'Champion',
                control: item => {
                  const filteredOptions = (championOptions ?? [])
                    .filter((x) => !formValues.champions
                      .map((y) => y.id?.toString())
                      .includes(x.value)
                    )
                  const selectedOption = item.id ? { value: item.id.toString(), label: item.fullName } : null
                  return (
                    <RadFormField field={`champions.${item.id || item.uuid}.id`}>
                      <RadSelect
                        selectedOption={selectedOption}
                        onChange={({ detail }) => {
                          const { champions } = formValues
                          const champion = formValues.champions.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                          champion.id = parseInt(detail.selectedOption.value)
                          champion.fullName = detail.selectedOption.label
                          setFormValues({ ...formValues, champions })
                        }}
                        options={filteredOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a champion'
                        filteringType='manual'
                        onLoadItems={({ detail }) => {
                          setChampionFilterText(detail.filteringText)
                        }}
                        empty={championFilterText ? 'No matches found' : null}
                      />
                    </RadFormField>
                  )
                }
              }
            ]}
            removeButtonText='Remove'
            empty='No champions added to this enrollment.'
          />
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function Goals ({ formValues, setFormValues }) {
  const { data: goalTypeOptions } = useGet('/api/option/type?entity=goal')
  const { data: goalProgressOptions } = useGet('/api/option/type?entity=goal_progress')

  const loading = goalTypeOptions == null || goalProgressOptions == null

  if (!loading) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h1'>Goals</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const { goals } = formValues
            goals.push({ uuid: uuidv4() })
            setFormValues(f => ({ ...f, goals }))
          }}
          onRemoveButtonClick={({ detail: { itemIndex } }) => {
            const { goals } = formValues
            goals.splice(itemIndex, 1)
            setFormValues(f => ({ ...f, goals }))
          }}
          items={formValues.goals}
          addButtonText='Add new goal'
          definition={[
            {
              control: item => {
                const selectedGoalTypeOption = item.goalTypeId
                  ? {
                      value: item.goalTypeId.toString(),
                      label: item.goalType?.name ?? item.goalTypeName
                    }
                  : null
                const selectedGoalProgressOption = item.goalProgressId
                  ? {
                      value: item.goalProgressId.toString(),
                      label: item.goalProgress?.name ?? item.goalProgressName
                    }
                  : null
                return (
                  <RadSpaceBetween size='l'>
                    <RadSpaceBetween size='l' direction='horizontal'>
                      <RadFormField
                        label='Goal Type'
                        field={`goals.${item.id || item.uuid}.id`}
                      >
                        <RadSelect
                          selectedOption={selectedGoalTypeOption}
                          onChange={({ detail }) => {
                            const { goals } = formValues
                            const goal = formValues.goals.find((goal) =>
                              goal.uuid === (item.uuid ?? '') ||
                                goal.id === (item.id ?? '')
                            )
                            goal.goalTypeId = parseInt(detail.selectedOption.value)
                            goal.goalTypeName = detail.selectedOption.label
                            setFormValues(f => ({ ...f, goals }))
                          }}
                          options={goalTypeOptions}
                          enteredTextLabel={value => value}
                          selectedAriaLabel='Selected'
                          placeholder='Choose a goal type'
                          expandToViewport
                        />
                      </RadFormField>
                      <RadFormField
                        label='Progress'
                        field={`goals.${item.goalProgressId || item.uuid}.goalProgressId`}
                      >
                        <RadSelect
                          selectedOption={selectedGoalProgressOption}
                          onChange={({ detail }) => {
                            const { goals } = formValues
                            const goal = formValues.goals.find((goal) =>
                              goal.uuid === (item.uuid ?? '') ||
                                goal.id === (item.id ?? '')
                            )
                            goal.goalProgressId = parseInt(detail.selectedOption.value)
                            goal.goalProgressName = detail.selectedOption.label
                            setFormValues(f => ({ ...f, goals }))
                          }}
                          options={goalProgressOptions}
                          enteredTextLabel={value => value}
                          selectedAriaLabel='Selected'
                          placeholder='Select goal progress'
                          expandToViewport
                        />
                      </RadFormField>
                      <RadFormField label='Target Date' field='targetDate'>
                        <RadDatePicker
                          onChange={({ detail }) => {
                            const { goals } = formValues
                            const goal = formValues.goals.find((goal) =>
                              goal.uuid === (item.uuid ?? '') ||
                              goal.id === (item.id ?? '')
                            )
                            goal.targetDate = detail.value
                            setFormValues(f => ({ ...f, goals }))
                          }}
                          value={item.targetDate ?? ''}
                          openCalendarAriaLabel={selectedDate =>
                            'Choose a target date' +
                            (selectedDate
                              ? `, selected date is ${selectedDate}`
                              : '')}
                          expandToViewport
                        />
                      </RadFormField>
                    </RadSpaceBetween>
                    <RadFormField label='Text' field='text'>
                      <RadTextarea
                        placeholder='Enter text'
                        value={item.text ?? ''}
                        onChange={({ detail }) => {
                          const { goals } = formValues
                          const goal = formValues.goals.find((goal) =>
                            goal.uuid === (item.uuid ?? '') ||
                            goal.id === (item.id ?? '')
                          )
                          goal.text = detail.value
                          setFormValues(f => ({ ...f, goals }))
                        }}
                      />
                    </RadFormField>
                    {item !== formValues.goals[formValues.goals.length - 1] && <RadDivider />}
                  </RadSpaceBetween>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No goals added to this enrollment.'
        />
      </RadContainer>
    )
  }
}

function Close ({ formValues, setFormValues }) {
  const { data: exitReasonOptions } = useGet('/api/option/type?entity=exit_reason')

  if (exitReasonOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h1'>Close</RadHeader>
      }
      >
        <RadSpaceBetween size='l'>
          <RadFormField label='Closed' field='closedAt'>
            <RadDatePicker
              onChange={({ detail }) => setFormValues({ ...formValues, closedAt: detail.value })}
              value={formValues.closedAt ?? ''}
              openCalendarAriaLabel={selectedDate =>
                'Choose a date' +
              (selectedDate
                ? `, selected date is ${selectedDate}`
                : '')}
            />
          </RadFormField>
          <RadFormField label='Exit Reason'>
            <RadSelect
              selectedOption={exitReasonOptions.find(x => x.value === formValues.exitReasonId?.toString())}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  setFormValues({ ...formValues, exitReasonId: null })
                } else {
                  setFormValues({ ...formValues, exitReasonId: parseInt(detail.selectedOption.value) })
                }
              }}
              options={exitReasonOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose an exit reason'
              empty='No matches found'
            />
          </RadFormField>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}
