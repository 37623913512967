import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadTable } from '../common/RadTable'
import { RadButton } from '../common/RadButton'
import { getGrade, formatDate, humanReadableId } from '../common/utilities'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'

export function PersonDetail ({ type }) {
  const navigate = useNavigate()
  const { personId } = useParams()
  const { data: person } = useGet(`/api/person/${personId}?type=${type}`)
  const [sessionsSort, setSessionsSort] = useState(['date', true])
  const remove = useDelete(`/api/person/${personId}`, () => navigate('/person'))
  const confirmDelete = useConfirm(
    'Delete person?',
    'Delete person permanently? This action cannot be undone.',
    () => { remove() }
  )

  const typeSpecific = {
    student: {
      headerActions: [
        <RadButton key={1} onClick={() => navigate(`/enrollment/create?studentId=${personId}`)}>
          Create Focus List Enrollment
        </RadButton>,
        <RadButton key={2} onClick={() => navigate(`/session/create?studentId=${personId}`)}>
          Create Session
        </RadButton>,
        <RadButton key={3} onClick={() => navigate('guardian-messages')}>
          Guardian Messages
        </RadButton>
      ]
    },
    person: { headerActions: [] }
  }

  const { headerActions } = typeSpecific[type]
  const isChampion = type === 'person'
  const isStudent = !isChampion

  if (person != null) {
    return (
      <RadAppLayout
        name={`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''} ${person.lastName}`}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                {isChampion && <RadButton onClick={() => confirmDelete()}>Delete</RadButton>}
                {headerActions}
              </RadSpaceBetween>
            }
          >
            {`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''} ${person.lastName}`}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                  {person.photoUrl && <img className='profile' src={person.photoUrl} alt={`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''}${person.lastName}`} referrerPolicy='no-referrer' />}
                </RadHeader>
              }
            >
              <RadColumnLayout columns={isStudent ? 3 : 1} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Full Name
                  </RadBox>
                  <div>{`${person.firstName} ${person.middleName ? person.middleName + ' ' : ''} ${person.lastName}`}</div>
                </div>
                {
                  isStudent &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Birthdate
                      </RadBox>
                      <div>{person.dateOfBirth ? formatDate(person.dateOfBirth) : '-'}</div>
                    </div>
                }
                {
                  isStudent &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Gender
                      </RadBox>
                      <div>{person.gender?.replace('M', 'Male')?.replace('F', 'Female') ?? '-'}</div>
                    </div>
                }
                {
                  isStudent &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Ethnicity
                      </RadBox>
                      <div>{person.ethnicities ?? '-'}</div>
                    </div>
                }
                <div>
                  <RadBox variant='awsui-key-label'>
                    Email
                  </RadBox>
                  <div>{person.email ? <RadLink href={`mailto:${person.email}`}>{person.email}</RadLink> : '-'}</div>
                </div>
                {
                  isStudent &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Phone
                      </RadBox>
                      <div>{person.phone ?? '-'}</div>
                    </div>
                }
                {
                  isStudent &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Guardians
                      </RadBox>
                      {person.guardians.length > 0
                        ? person.guardians.map((x) => {
                          return (
                            <div key={`guardian-${x.id}`}>{x.firstName} {x.lastName} {x.phone != null ? '-' : ''} <RadLink href={`tel:${x.phone}`}>{x.phone}</RadLink></div>
                          )
                        })
                        : '-'}
                    </div>
                }
                {
                  isStudent &&
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Guardian Emails
                      </RadBox>
                      {person.guardianEmails.length > 0
                        ? person.guardianEmails.map((x) => {
                          return (
                            <div key={`guardian-email-${x.id}`}><RadLink href={`mailto:${x.email}`}>{x.email}</RadLink></div>
                          )
                        })
                        : '-'}
                    </div>
                }
              </RadColumnLayout>
              {
                isStudent &&
                  <div>
                    <RadBox variant='awsui-key-label' padding={{ top: 'l' }}>
                      Groups
                    </RadBox>
                    {person.groups.length > 0
                      ? person.groups.map((x) => {
                        return (
                          <div key={x.id}><RadLink href={`/group/${x.id}`}>{x.name}</RadLink></div>
                        )
                      })
                      : '-'}
                  </div>
              }
            </RadContainer>
            {
              isStudent && person.cases.length > 0 &&
                <RadTable
                  header={
                    <RadHeader>
                      Focus List Enrollments
                    </RadHeader>
                  }
                  columnDefinitions={[
                    {
                      id: 'caseId',
                      header: 'Case Number',
                      cell: e => <RadLink href={`/enrollment/${humanReadableId(e.id)}`}>{humanReadableId(e.id)}</RadLink>
                    },
                    {
                      id: 'indicator',
                      header: 'Indicator',
                      cell: e => e.service.name
                    },
                    {
                      id: 'description',
                      header: 'Description',
                      cell: e => e.description
                    },
                    {
                      id: 'status',
                      header: 'Status',
                      cell: e => e.status?.name ?? '-'
                    },
                    {
                      id: 'owner',
                      header: 'Owner',
                      cell: e => <RadLink href={`/user/${e.owner.id}`}>{e.owner.name}</RadLink>
                    },
                    {
                      id: 'openedAt',
                      header: 'Opened',
                      cell: e => formatDate(e.openedAt)
                    },
                    {
                      id: 'closedAt',
                      header: 'Closed',
                      cell: e => formatDate(e.closedAt)
                    }
                  ]}
                  items={person.cases}
                  variant='container'
                />
            }
            {
              isStudent && person.sessions.length > 0 &&
                <RadTable
                  wrapLines
                  contentDensity='compact'
                  header={
                    <RadHeader>
                      Sessions
                    </RadHeader>
                  }
                  columnDefinitions={[
                    {
                      id: 'id',
                      sortingField: 'id',
                      header: 'Number',
                      cell: s =>
                        <RadLink href={`/session/${humanReadableId(s.id)}`}>
                          {humanReadableId(s.id)}
                        </RadLink>
                    },
                    {
                      id: 'indicator',
                      header: 'Indicator',
                      cell: s => s.deliveries.map((x) =>
                        <div key={x.id}>{x.serviceDelivery.service.name}</div>)
                    },
                    {
                      id: 'intervention',
                      header: 'Intervention',
                      cell: s => s.deliveries.map((x) =>
                        <div key={x.id}>{x.serviceDelivery.name}</div>
                      )
                    },
                    {
                      id: 'skills',
                      header: 'Skills',
                      cell: s => s.deliveries.map((x) =>
                        <div key={x.id}>
                          {x.skills.map((y) => y.skill.name).join(', ')}
                        </div>
                      )
                    },
                    {
                      id: 'minutes',
                      header: 'Minutes',
                      cell: s => s.minutes ?? s.deliveries.reduce((a, b) => a + b.minutes, 0)
                    },
                    {
                      id: 'notes',
                      header: 'Notes',
                      cell: s => s.notes ?? '-'
                    },
                    {
                      id: 'date',
                      sortingField: 'date',
                      header: 'Date',
                      cell: s => formatDate(s.date)
                    },
                    {
                      id: 'owner',
                      header: 'Administered By',
                      cell: s =>
                        <RadLink href={`/user/${s.administeredBy}`}>{s.owner.name}</RadLink>
                    }
                  ]}
                  items={person.sessions.sort((a, b) => {
                    const sortOrder = sessionsSort[1] ? 1 : -1
                    return a[sessionsSort[0]] < b[sessionsSort[0]] ? sortOrder : -sortOrder
                  })}
                  variant='container'
                  sortingColumn={{ sortingField: sessionsSort[0] }}
                  sortingDescending={sessionsSort[1]}
                  onSortingChange={({ detail: { sortingColumn: { id: column }, isDescending } }) => {
                    setSessionsSort([column, isDescending])
                  }}
                />
            }
            {
              person.schoolEnrollments.length > 0 &&
                <>
                  <RadTable
                    header={
                      <RadHeader>
                        School Enrollments
                      </RadHeader>
                    }
                    columnDefinitions={[
                      {
                        id: 'studentNumber',
                        header: 'Student #',
                        cell: e => e.studentNumber
                      },
                      {
                        id: 'school',
                        header: 'School',
                        cell: e => <RadLink href={`/organization/${e.school.organizationId}`}>{e.school.name}</RadLink>
                      },
                      {
                        id: 'grade',
                        header: 'Grade',
                        cell: e => getGrade(e.grade)
                      },
                      {
                        id: 'startDate',
                        header: 'Start Date',
                        cell: e => formatDate(e.startDate)
                      },
                      {
                        id: 'endDate',
                        header: 'End Date',
                        cell: e => formatDate(e.endDate)
                      }
                    ]}
                    items={person.schoolEnrollments}
                    variant='container'
                  />
                  <RadTable
                    header={
                      <RadHeader>
                        Classes
                      </RadHeader>
                    }
                    columnDefinitions={[
                      {
                        id: 'course',
                        header: 'Course',
                        cell: e => e.course.name
                      },
                      {
                        id: 'teacher',
                        header: 'Teacher',
                        cell: e => e.teacherId ? `${e.teacher.firstName} ${e.teacher.lastName}` : '-'
                      },
                      {
                        id: 'class',
                        header: 'Class',
                        cell: e => e.id
                      },
                      {
                        id: 'startDate',
                        header: 'Start Date',
                        cell: e => formatDate(e.SchoolEnrollmentSection.startDate)
                      },
                      {
                        id: 'EndDate',
                        header: 'End Date',
                        cell: e => formatDate(e.SchoolEnrollmentSection.endDate)
                      },
                      {
                        id: 'q1',
                        header: 'Q1',
                        cell: e => {
                          const grade = e.grades.find((x) => x.type === 'Q1')
                          return grade ? `${grade.letterGrade} ${grade.percent}%` : '-'
                        }
                      },
                      {
                        id: 'q2',
                        header: 'Q2',
                        cell: e => {
                          const grade = e.grades.find((x) => x.type === 'Q2')
                          return grade ? `${grade.letterGrade} ${grade.percent}%` : '-'
                        }
                      },
                      {
                        id: 's1',
                        header: 'S1',
                        cell: e => {
                          const grade = e.grades.find((x) => x.type === 'S1')
                          return grade ? `${grade.letterGrade} ${grade.percent}%` : '-'
                        }
                      },
                      {
                        id: 'q3',
                        header: 'Q3',
                        cell: e => {
                          const grade = e.grades.find((x) => x.type === 'Q3')
                          return grade ? `${grade.letterGrade} ${grade.percent}%` : '-'
                        }
                      },
                      {
                        id: 'q4',
                        header: 'Q4',
                        cell: e => {
                          const grade = e.grades.find((x) => x.type === 'Q4')
                          return grade ? `${grade.letterGrade} ${grade.percent}%` : '-'
                        }
                      },
                      {
                        id: 's2',
                        header: 'S2',
                        cell: e => {
                          const grade = e.grades.find((x) => x.type === 'S2')
                          return grade ? `${grade.letterGrade} ${grade.percent}%` : '-'
                        }
                      },
                      {
                        id: 'y1',
                        header: 'Y1',
                        cell: e => {
                          const grade = e.grades.find((x) => x.type === 'Y1')
                          return grade ? `${grade.letterGrade} ${grade.percent}%` : '-'
                        }
                      }
                    ]}
                    items={person.schoolEnrollments[0].sections}
                    variant='container'
                    empty={
                      <RadBox
                        padding={{ bottom: 's' }}
                        variant='p'
                        color='inherit'
                      >
                        No classes
                      </RadBox>
                    }
                    wrapLines
                  />
                  <RadTable
                    header={
                      <RadHeader>
                        Disciplinary Actions
                      </RadHeader>
                    }
                    columnDefinitions={[
                      {
                        id: 'date',
                        header: 'Date',
                        cell: e => formatDate(e.date)
                      },
                      {
                        id: 'tier',
                        header: 'Tier',
                        cell: e => e.tier.name
                      },
                      {
                        id: 'type',
                        header: 'Type',
                        cell: e => e.type
                      },
                      {
                        id: 'description',
                        header: 'Description',
                        cell: e => e.description
                      },
                      {
                        id: 'days',
                        header: 'Days',
                        cell: e => e.days
                      }
                    ]}
                    items={person.schoolEnrollments[0].disciplinaryActions}
                    variant='container'
                    empty={
                      <RadBox
                        padding={{ bottom: 's' }}
                        variant='p'
                        color='inherit'
                      >
                        No disciplinary actions
                      </RadBox>
                    }
                  />
                  <RadTable
                    header={
                      <RadHeader>
                        Absences
                      </RadHeader>
                    }
                    columnDefinitions={[
                      {
                        id: 'date',
                        header: 'Date',
                        cell: e => formatDate(e.date)
                      },
                      {
                        id: 'duration',
                        header: 'Days',
                        cell: e => e.duration
                      }
                    ]}
                    items={person.schoolEnrollments[0].absences}
                    variant='container'
                    empty={
                      <RadBox
                        padding={{ bottom: 's' }}
                        variant='p'
                        color='inherit'
                      >
                        No absences actions
                      </RadBox>
                    }
                  />
                </>
            }
          </RadSpaceBetween>
        }
      />
    )
  }
}
