import { useContext, useEffect, useMemo, useState } from 'react'
import { formatNumber } from '../../common/utilities'
import { RadSpaceBetween } from '../../common/RadSpaceBetween'
import { RadButton } from '../../common/RadButton'
import { RadForm } from '../../common/RadForm'
import { RadFormField } from '../../common/RadFormField'
import { RadInput } from '../../common/RadInput'
import { RadTextarea } from '../../common/RadTextarea'
import { usePost } from '../../hooks/usePost'
import { AppContext } from '../../App'

export function ConversationForm ({ personId, userInfo, conversation, setSearchParams }) {
  const [recipients, setRecipients] = useState([''])
  const [messageText, setMessageText] = useState('')
  const [name, setName] = useState('')
  const { setError } = useContext(AppContext)
  const maxLength = useMemo(
    () => { return 1600 - `City Year-${userInfo?.name}: `.length },
    [userInfo?.name]
  )

  useEffect(() => {
    if (messageText.length > maxLength) {
      setError({
        validationErrors: {
          messageText: `Message text must be less than ${maxLength} characters.`
        },
        code: 'Validation Error'
      })
    } else {
      setError(null)
    }
  }, [messageText, maxLength, setError])

  const sendMessage = usePost(
    conversation == null ? '/api/conversation' : `/api/conversation/${conversation.sid}`,
    conversation == null ? { personId, recipients, messageText, name } : { messageText },
    (resp) => {
      if (conversation == null) {
        setRecipients([''])
        setName('')
        setSearchParams(p => {
          p.set('conversation', resp.sid)
          return p
        })
      }

      setMessageText('')
    }
  )
  async function submit (e) {
    e.preventDefault()
    sendMessage()
  }

  return (
    <RadForm
      actions={
        <RadSpaceBetween direction='horizontal' size='xs'>
          <RadButton
            disabled={
              (recipients.filter(x => x != null && x.length === 10).length < 1 && conversation == null) ||
              messageText === '' ||
              messageText.length > maxLength
            }
            onClick={submit}
            variant='primary'
            iconName='contact'
          >
            Send
          </RadButton>
        </RadSpaceBetween>
      }
    >
      <RadSpaceBetween size='xs' direction='vertical'>
        {conversation == null && (
          <RadFormField label='Name' field='name'>
            <RadInput
              placeholder='Enter name'
              value={name}
              onChange={({ detail }) => setName(detail.value)}
            />
          </RadFormField>
        )}
        {conversation == null && recipients.map((r, i) => (
          <RadFormField key={i} label='Phone Number' field={`recipient[${i}]`}>
            <RadInput
              inputMode='tel'
              placeholder='Enter number'
              value={formatNumber(recipients[i]) ?? ''}
              onChange={({ detail }) => {
                const value = detail.value.replace(/[^0-9]/g, '').substring(0, 10)
                const newRecipients = [...recipients]
                newRecipients[i] = value
                setRecipients(newRecipients)
              }}
            />
          </RadFormField>
          // {false && i === recipients.length - 1 && (
          //   <RadButton
          //     disabled={recipients[i] == null || recipients[i].length !== 10}
          //     onClick={() => setRecipients([...recipients, ''])}
          //     iconName='add-plus'
          //   />
          // )}
        ))}
        <RadFormField label='Message' field='messageText' stretch>
          <RadTextarea
            placeholder='Enter message'
            rows={5}
            onChange={({ detail }) => setMessageText(detail.value)}
            value={messageText}
          />
        </RadFormField>
      </RadSpaceBetween>
    </RadForm>
  )
}
