import { useRef, useEffect } from 'react'
import { formatNumber, formatDate } from '../../common/utilities'
import { RadBox } from '../../common/RadBox'
import { RadSpaceBetween } from '../../common/RadSpaceBetween'
import { RadHeader } from '../../common/RadHeader'
import { RadCheckbox } from '../../common/RadCheckbox'
import { RadLink } from '../../common/RadLink'
import { RadIcon } from '../../common/RadIcon'

export function MessageThread ({
  personId,
  conversationSid,
  messages,
  selecting,
  selectedMessages,
  setSelectedMessages,
  searchParams
}) {
  const endOfChat = useRef(null)
  const target = useRef(null)
  const targetSid = searchParams?.get('message') ?? null

  useEffect(() => {
    if (target.current != null) {
      target.current.scrollIntoView({ behavior: 'auto' })
      return
    }

    if (endOfChat.current != null) {
      endOfChat.current.scrollIntoView({ behavior: 'auto' })
    }
  }, [messages])

  return messages.length > 0 && (
    <div className='messages'>
      <RadSpaceBetween size='xs' direction='vertical'>
        {messages
          .sort((a, b) => a.dateCreated - b.dateCreated)
          .map((message, i) => {
            const isUser = message.author.includes('@')
            const isTarget = targetSid === message.sid
            const isSent = message.aggregatedDeliveryReceipt?.sent === 'all'
            const isDelivered = message.aggregatedDeliveryReceipt?.delivered === 'all'
            const isUndelivered = message.aggregatedDeliveryReceipt?.undelivered === 'all'
            const isRead = message.aggregatedDeliveryReceipt?.read === 'all'
            const isFailed = message.aggregatedDeliveryReceipt?.failed === 'all'
            const deliveryStatus = isRead ? 'Read' : isDelivered ? 'Delivered' : isUndelivered ? 'Undelivered' : isSent ? 'Sent' : isFailed ? 'Failed' : null
            const deliveryStatusColor = isRead ? 'green' : isDelivered ? 'green' : isUndelivered ? 'red' : isSent ? 'black' : isFailed ? 'red' : null
            console.log('deliveryStatus', message.aggregatedDeliveryReceipt)

            return (
              <div
                key={message.sid}
                className={`${isUser ? 'user' : 'student'}`}
              >
                {isTarget && (<div ref={target} />)}
                <RadSpaceBetween direction='vertical' size='xxs'>
                  <RadHeader
                    variant='h4'
                    actions={
                      <RadBox>
                        {!selecting && isTarget && (
                          <RadIcon variant='subtle' name='caret-left-filled' />
                        )}
                      </RadBox>
                    }
                  />
                  <div className='message'>
                    {selecting &&
                      <RadCheckbox
                        checked={selectedMessages.includes(message)}
                        onChange={({ detail }) => {
                          if (detail.checked) {
                            setSelectedMessages(s => [...s, message])
                          } else {
                            setSelectedMessages(s => s.filter(m => m.sid !== message.sid))
                          }
                        }}
                      >
                        <span style={{ whiteSpace: 'pre-line', color: 'white' }}>{message.body}</span>
                      </RadCheckbox>}
                    {!selecting && <span style={{ whiteSpace: 'pre-line' }}>{message.body}</span>}
                  </div>
                  <div className='subtext'>
                    <RadSpaceBetween direction='vertical' size='xxxxs'>
                      {isUser ? message.author.split('|')[1] : formatNumber(message.author)}
                      {isUser && deliveryStatus != null && <span style={{ color: deliveryStatusColor }}>{deliveryStatus}</span>}
                      <RadBox variant='small'>
                        <RadLink href={[
                          `/student/${personId}/guardian-messages`,
                          `?conversation=${conversationSid}`,
                          `&message=${message.sid}`
                        ].join('')}
                        >
                          {formatDate(message.dateCreated.toString(), true)}
                        </RadLink>
                      </RadBox>
                    </RadSpaceBetween>
                  </div>
                </RadSpaceBetween>
                {i === messages.length - 1 && (<div ref={endOfChat} />)}
              </div>
            )
          })}
      </RadSpaceBetween>
    </div>
  )
}
