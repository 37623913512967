import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadAvatar } from '../common/RadAvatar'
import { RadLink } from '../common/RadLink'
import { RadTable } from '../common/RadTable'
import { getGrade, formatDate, humanReadableId } from '../common/utilities'

export function CaseDetail () {
  const navigate = useNavigate()
  const { enrollmentId } = useParams()
  const { data: casey } = useGet(enrollmentId ? `/api/case/${enrollmentId}` : null)
  const confirmDelete = useConfirm('Delete focus list enrollment?', 'Delete focus list enrollment permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/case/${enrollmentId}`, () => { navigate('/enrollment') })

  if (casey) {
    return (
      <RadAppLayout
        name={humanReadableId(casey.id)}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={casey.description}
          >
            {humanReadableId(casey.id)}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='m'>
            <Details {...{ casey }} />
            <Sessions {... { casey }} />
            <Goals {... { casey }} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

const Details = ({ casey }) => {
  const navigate = useNavigate()
  const Champions = ({ champions }) => {
    if (champions.length < 1) {
      return (<>-</>)
    }

    return (
      <RadSpaceBetween size='xxxs'>
        {champions.map((champion) => (
          <RadLink key={champion.id} href={`/person/${champion.id}`}>{champion.fullName}</RadLink>
        ))}
      </RadSpaceBetween>
    )
  }

  if (casey == null) { return null }

  return (
    <RadContainer onClick={() => navigate(`/student/${casey.person.id}`)}>
      <RadSpaceBetween size='xs'>
        <RadAvatar
          src={casey.person.photoUrl}
          size='large'
          name={`${casey.person.firstName} ${casey.person.preferredName ? '"' + casey.person.preferredName + '" ' : ''} ${casey.person.lastName}`}
          referrerPolicy='no-referrer'
        />
        <b><RadLink fontSize='heading-l' href={`/student/${casey.person.id}`}>{`${casey.person?.firstName} ${casey.person.preferredName ? '"' + casey.person.preferredName + '" ' : ''} ${casey.person?.lastName}`}</RadLink></b>
        {casey.person.schoolEnrollments?.length > 0
          ? <div>{casey.person.schoolEnrollments[0].school.name}<br />{getGrade(casey.person.schoolEnrollments[0].grade)}</div>
          : <div />}
      </RadSpaceBetween>
      <br />
      <br />
      <br />
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Indicator
          </RadBox>
          {casey.service.name ?? '-'}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Opened
          </RadBox>
          {formatDate(casey.openedAt) ?? '-'}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Description
          </RadBox>
          {casey.description ?? '-'}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Status
          </RadBox>
          {casey.status?.name ?? '-'}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Owner
          </RadBox>
          <RadLink href={`/user/${casey.owner.id}`}>{casey.owner.name}</RadLink>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Champions
          </RadBox>
          <Champions champions={casey.champions} />
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Closed
          </RadBox>
          {formatDate(casey.closedAt) ?? '-'}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Exit Reason
          </RadBox>
          {casey.exitReason?.name ?? '-'}
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}

const Sessions = ({ casey }) => {
  const { data: person } = useGet(casey?.personId
    ? `/api/person/${casey.personId}?type=student`
    : null
  )

  if (casey == null) { return null }

  const openedAt = casey.openedAt != null ? new Date(casey.openedAt) : null
  const closedAt = casey.closedAt != null ? new Date(casey.closedAt) : null
  const filteredSessions = person?.sessions.filter((x) =>
    (openedAt == null || new Date(x.date) >= openedAt) &&
    (closedAt == null || new Date(x.date) <= closedAt) &&
    x.deliveries.filter((y) =>
      y.serviceDelivery.serviceId === casey.serviceId
    ).length > 0
  )

  return (
    filteredSessions != null &&
    filteredSessions.length > 0 &&
      <RadTable
        header={
          <RadHeader>
            Sessions
          </RadHeader>
        }
        columnDefinitions={[
          {
            id: 'sessionId',
            header: 'Number',
            cell: e =>
              <RadLink href={`/session/${humanReadableId(e.id)}`}>
                {humanReadableId(e.id)}
              </RadLink>
          },
          {
            id: 'date',
            header: 'Date',
            cell: e => formatDate(e.date)
          },
          {
            id: 'indicator',
            header: 'Indicator',
            cell: e => e.deliveries.map((x) =>
              <div key={x.id}>{x.serviceDelivery.service.name}</div>)
          },
          {
            id: 'intervention',
            header: 'Intervention',
            cell: e => e.deliveries.map((x) =>
              <div key={x.id}>{x.serviceDelivery.name}</div>)
          },
          {
            id: 'skills',
            header: 'Skills',
            cell: e => e.deliveries.map((x) =>
              <div key={x.id}>
                {x.skills.map((y) => y.skill.name).join(', ')}
              </div>
            )
          },
          {
            id: 'minutes',
            header: 'Minutes',
            cell: e => e.minutes ?? e.deliveries.map((x) =>
              <div key={x.id}>{x.minutes}</div>)
          },
          {
            id: 'notes',
            header: 'Notes',
            cell: e => e.notes
          }
        ]}
        items={filteredSessions}
        variant='container'
        wrapLines
      />
  )
}

const Goals = ({ casey }) => {
  if (casey == null) { return null }

  const { goals } = casey

  return (
    goals.length > 0 && <RadTable
      header={<RadHeader> Goals </RadHeader>}
      columnDefinitions={[
        {
          id: 'goalType',
          header: 'Goal Type',
          cell: e => e.goalType.name
        },
        {
          id: 'goalProgress',
          header: 'Progress',
          cell: e => e.goalProgress?.name ?? '-'
        },
        {
          id: 'targetDate',
          header: 'Target Date',
          cell: e => formatDate(e.targetDate) ?? '-'
        },
        {
          id: 'text',
          header: 'Text',
          cell: e => e.text ?? '-'
        }
      ]}
      items={goals}
      variant='container'
      wrapLines
                        />
  )
}
