import moment from 'moment'

export const removeEmptyStrings = (obj) => {
  const isArray = Array.isArray(obj)
  for (const k of Object.keys(obj)) {
    if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'string' && obj[k].trim() === '') {
      obj[k] = null
    } else if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'object') {
      removeEmptyStrings(obj[k])
    }
    if (isArray && obj.length === k) {
      removeEmptyStrings(obj)
    }
  }
  return obj
}

export function formatDate (inputString, withTime = false) {
  if (inputString == null) {
    return null
  }
  const format = withTime ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY'
  return moment(inputString).format(format)
}

export function formatNumber (number) {
  return number
    ? number
      .replace('+1', '')
      .replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    : null
}
export function isValidDate (inputString) {
  if (inputString == null) return false
  if (inputString.length !== 10) return false
  const date = moment(inputString, 'YYYY/MM/DD')
  return date.isValid()
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export function toTitleCase (str) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export function getGrade (grade) {
  switch (grade) {
    case -2:
      return 'Daycare'
    case -1:
      return 'Preschool'
    case -0:
      return 'Kindergarten'
    default:
      return getOrdinal(grade) + ' grade'
  }
}

// Chipi - find a better name
export function humanReadableId (id) {
  return id.toString().padStart(7, '0')
}

export function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
