import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment'
import { usePost } from '../../hooks/usePost'
import { useGet } from '../../hooks/useGet'
import { RadButton } from '../../common/RadButton'
import { RadSpaceBetween } from '../../common/RadSpaceBetween'
import { RadModal } from '../../common/RadModal'
import { RadHeader } from '../../common/RadHeader'
import { RadSelect } from '../../common/RadSelect'
import { RadInput } from '../../common/RadInput'
import { RadTextarea } from '../../common/RadTextarea'
import { RadDatePicker } from '../../common/RadDatePicker'
import { RadFormField } from '../../common/RadFormField'
import { RadBox } from '../../common/RadBox'
import { MessageThread } from './MessageThread'

export function LogSessionFromMessageForm ({
  personId,
  conversationSid,
  selectedMessages,
  onSuccess,
  onDismiss
}) {
  const { data: userInfo } = useGet('/api/user/current')
  const { data: programs } = useGet('/api/program')
  const { data: programOptions } = useGet('/api/option/program?required=true')
  const { data: tierOptions } = useGet('/api/option/type?entity=tier&required=true')
  const { data: reasonOptions } = useGet('/api/option/type?entity=session_reason&required=true')

  const defaultFormData = {
    date: selectedMessages.map(m => moment(m.dateCreated)).sort()[0].format('YYYY-MM-DD'),
    minutes: 1,
    programId: null,
    tierId: null,
    serviceId: null,
    serviceDeliveryId: null,
    reasonId: null,
    notes: null
  }
  const [formData, setFormData] = useState(defaultFormData)
  const create = usePost(
    '/api/session',
    {
      administeredBy: userInfo?.id,
      date: formData.date,
      deliveries: [{
        minutes: formData.minutes,
        programId: formData.programId,
        serviceDelivery: { serviceId: formData.serviceId },
        serviceDeliveryId: formData.serviceDeliveryId,
        skills: [],
        tierId: formData.tierId,
        uuid: '0'
      }],
      people: [{
        id: personId,
        SessionPerson: { notes: formData.notes }
      }],
      twilioConversationSid: conversationSid,
      twilioMessages: selectedMessages.map(m => ({
        dateCreated: m.dateCreated,
        sid: m.sid
      })),
      reasonId: formData.reasonId
    },
    (resp) => {
      setFormData(defaultFormData)
      onSuccess(resp)
    }
  )

  useEffect(() => {
    if (userInfo == null) return

    setFormData(f => ({
      ...f,
      programId: userInfo.roles
        .filter(r => r.primaryProgramId != null)
        .map(r => r.primaryProgramId)[0]
    }))
  }, [userInfo])

  const serviceOptions = useMemo(() => {
    if (!formData.programId || !programs) return []

    const program = programs.find((p) => p.id === formData.programId)

    return program.services
      .filter(s => s.deliveries.some(d => d.name === 'Communication Home'))
      .map(s => ({ value: s.id.toString(), label: s.name }))
  }, [formData.programId, programs])

  const deliveryOptions = useMemo(() => {
    if (programs == null || formData.serviceId == null) return []

    const deliveryOption = programs
      .flatMap(p => p.services.flatMap(s => s.deliveries))
      .filter(d => d.serviceId === formData.serviceId)
      .find(d => d.name === 'Communication Home')

    setFormData(f => ({ ...f, serviceDeliveryId: deliveryOption.id }))

    return [{ value: deliveryOption.id.toString(), label: deliveryOption.name }]
  }, [formData.serviceId, programs])

  const loading = programs == null ||
    programOptions == null ||
    tierOptions == null ||
    userInfo == null ||
    reasonOptions == null
  if (loading) return null

  const canSubmit = selectedMessages.length > 0 &&
    formData.programId &&
    formData.tierId &&
    formData.serviceId &&
    formData.serviceDeliveryId &&
    formData.reasonId

  return (
    <RadModal
      onDismiss={onDismiss}
      visible
      header={<RadHeader variant='h2'>Create Session</RadHeader>}
      footer={
        <RadSpaceBetween direction='horizontal' size='xs'>
          <RadButton
            onClick={() => {
              setFormData(defaultFormData)
              onDismiss()
            }}
          >
            Cancel
          </RadButton>
          <RadButton disabled={!canSubmit} onClick={create} variant='primary'>Create</RadButton>
        </RadSpaceBetween>
      }
    >
      <RadSpaceBetween size='xs' direction='vertical'>
        <MessageThread
          personId={personId}
          conversationSid={conversationSid}
          messages={selectedMessages}
        />
        <RadSpaceBetween direction='horizontal' size='s'>
          <RadFormField label='Date' field='date' stretch>
            <RadDatePicker disabled value={formData.date} />
          </RadFormField>
          <RadFormField label='Minutes' field='delivery.0.minutes'>
            <RadInput
              inputMode='numeric'
              value={formData.minutes}
              disabled
            />
          </RadFormField>
        </RadSpaceBetween>
        <RadFormField label='Program' field='delivery.0.programId' stretch>
          <RadSelect
            ariaRequired
            selectedOption={
              formData.programId
                ? programOptions.find(o => o.value === formData.programId.toString())
                : null
            }
            onChange={({ detail }) => {
              setFormData(f => ({
                ...f,
                programId: parseInt(detail.selectedOption.value)
              }))
            }}
            options={programOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a program'
            expandToViewport
          />
        </RadFormField>
        <RadFormField label='Tier' field='delivery.0.tierId' stretch>
          <RadSelect
            ariaRequired
            selectedOption={formData.tierId
              ? tierOptions.find((o) => o.value === formData.tierId.toString())
              : null}
            onChange={({ detail }) => {
              setFormData(f => ({
                ...f,
                tierId: parseInt(detail.selectedOption.value)
              }))
            }}
            options={tierOptions}
            enteredTextLabel={value => value}
            placeholder='Choose a tier'
            selectedAriaLabel='Selected'
            expandToViewport
          />
        </RadFormField>
        <RadFormField label='Indicator' field='delivery.0.service'>
          <RadBox variant='small'>Only Indicators with a 'Communication Home' Intervention are displayed here.</RadBox>
          <RadSelect
            ariaRequired
            selectedOption={serviceOptions.find((x) => {
              return x.value === formData.serviceId?.toString()
            })}
            onChange={({ detail }) => {
              setFormData(f => ({
                ...f,
                serviceId: parseInt(detail.selectedOption.value)
              }))
            }}
            filteringType='auto'
            options={serviceOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose an indicator'
            disabled={!formData.programId}
          />
        </RadFormField>
        <RadFormField label='Intervention' field='delivery.0.serviceDeliveryId'>
          <RadSelect
            ariaRequired
            selectedOption={deliveryOptions[0]}
            filteringType='auto'
            options={deliveryOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Communication Home'
            disabled
          />
        </RadFormField>
        <RadFormField label='Session Reason' field='reasonId'>
          <RadSelect
            ariaRequired
            selectedOption={reasonOptions.find((o) => o.value === formData.reasonId?.toString())}
            onChange={({ detail }) => {
              setFormData(f => ({
                ...f,
                reasonId: parseInt(detail.selectedOption.value)
              }))
            }}
            options={reasonOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a session reason'
          />
        </RadFormField>
        <RadFormField label='Notes' field='people.0.notes'>
          <RadTextarea
            value={formData.notes}
            placeholder='Enter notes'
            rows={formData.notes ? 3 : 1}
            onChange={({ detail }) => {
              setFormData(f => ({
                ...f,
                notes: detail.value
              }))
            }}
          />
        </RadFormField>
      </RadSpaceBetween>
    </RadModal>
  )
}
